import { TPaymentType } from "./../type/paymentPage/IPaymentPageData"
import DefaultCardLogo from "../images/creditCardLogos/card-blue.png"
import DankortLogo from "../images/creditCardLogos/dk.png"
import VisaLogo from "../images/creditCardLogos/VisaPaymentLogoV2.png"
import MasterCardLogo from "../images/creditCardLogos/mastercardV2.png"
import ForbrugsforeningenLogo from "../images/creditCardLogos/forbrugsforeningen.png"
import MobilePayOnlineLogo from "../images/paymentTypeLogo/mobilepay-logo-text.png"
import ViabillLogo from "../images/paymentTypeLogo/viabill-primary-logo.png"
import AnydayLogo from "../images/paymentTypeLogo/anyday-logo.svg"
import VippsLogo from "../images/paymentTypeLogo/vipps-logo.svg"
import SwishLogo from "../images/paymentTypeLogo/swish-logo.png"
import KlarnaLogo from "../images/paymentTypeLogo/klarna-payment-logo.svg"
import ApplePayLogo from "../images/paymentTypeLogo/applepay-dark-logo.svg"
import ResursLogo from "../images/paymentTypeLogo/resurs-icon.webp"

import { TCreditCardType } from "../type/paymentPage/IPaymentPageData"
import i18next from "i18next"

import { TImage } from "../locale/images/ITransalationImages"
import { daImages } from "../locale/images/imageDa"
import { enImages } from "../locale/images/imageEn"
import { getLanguage } from "./../locale/lang"

export function getImageByLanguage(image: TImage): string {
    const lang = getLanguage()
    switch (lang) {
        case "da":
            return daImages.images[image]
        case "en":
            return enImages.images[image]
        default:
            return daImages.images[image]
    }
}

export const getLogoByCardType = (type: TCreditCardType, onDefaultEmpty?: boolean) => {
    switch (type) {
        case "Visa":
            return VisaLogo
        case "MasterCard":
            return MasterCardLogo
        case "Dankort":
            return DankortLogo
        case "Forbrugsforeningen":
            return ForbrugsforeningenLogo
        default:
            return onDefaultEmpty ? "" : DefaultCardLogo
    }
}

export const getLogoByPaymentType = (type: TPaymentType) => {
    switch (type) {
        case "Swish":
            return SwishLogo
        case "MobilePayOnline":
            return MobilePayOnlineLogo
        case "Viabill":
            return ViabillLogo
        case "Anyday":
            return AnydayLogo
        case "AccountToAccount":
            return getImageByLanguage("bankLogos")
        case "CreditCard":
            return DefaultCardLogo
        case "Vipps":
            return VippsLogo
        case "KlarnaPayLater":
            return KlarnaLogo
        case "KlarnaFinancing":
            return KlarnaLogo
        case "ApplePay":
            return ApplePayLogo
        case "ResursInvoice":
            return ResursLogo
        case "ResursPartPayment":
            return ResursLogo
        case "ResursRevolvingCredit":
            return ResursLogo
        default:
            return ""
    }
}

export const getDescriptionByPaymentType = (type: TPaymentType) => {
    switch (type) {
        case "CreditCard":
            return i18next.t("paymentpage.paymentTypes.cardpayment")
        case "MobilePayOnline":
            return i18next.t("paymentpage.paymentTypes.mobilepay")
        case "Viabill":
            return i18next.t("paymentpage.paymentTypes.viabill")
        case "Anyday":
            return i18next.t("paymentpage.paymentTypes.anyday")
        case "AccountToAccount":
            return i18next.t("paymentpage.paymentTypes.a2a")
        case "Swish":
            return i18next.t("paymentpage.paymentTypes.swish")
        case "Vipps":
            return i18next.t("paymentpage.paymentTypes.vipps")
        case "KlarnaPayLater":
            return i18next.t("paymentpage.paymentTypes.klarnapaylater")
        case "KlarnaFinancing":
            return i18next.t("paymentpage.paymentTypes.klarnafinancing")
        case "ApplePay":
            return i18next.t("paymentpage.paymentTypes.applepay")
        case "ResursInvoice":
            return i18next.t("paymentpage.paymentTypes.resursinvoice")
        case "ResursPartPayment":
            return i18next.t("paymentpage.paymentTypes.resurspartpayment")
        case "ResursRevolvingCredit":
            return i18next.t("paymentpage.paymentTypes.resursrevolvingcredit")
        default:
            return ""
    }
}
