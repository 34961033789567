import { Browser, detect } from "detect-browser"
import {
    IApplePayConfiguration,
    IPaymentLinkSupportedPaymentType,
    IResursConfiguration,
    TCurrency,
    TPaymentType,
} from "../type/paymentPage/IPaymentPageData"

interface IBrowserCheckModel {
    paymentType: TPaymentType
    supportedBrowsers: ISupportedBrowser[]
}
interface ISupportedBrowser {
    browser: Browser
    minVersion?: string
    maxVersion?: string
}

export const isPaymentTypeBrowserSupported = (paymentType: TPaymentType) => {
    switch (paymentType) {
        case "ApplePay":
            return isPaymentTypeSupportedForBrowser({
                paymentType: "ApplePay",
                supportedBrowsers: [{ browser: "safari" }, { browser: "ios" }],
            })
        default:
            return true
    }
}

const isPaymentTypeSupportedForBrowser = (model: IBrowserCheckModel) => {
    const currentBrowser = detect()
    if (!currentBrowser) {
        return false
    }

    const supportedBrowser = model.supportedBrowsers.find((supportedBrowser) => {
        const isBrowser = supportedBrowser.browser == currentBrowser.name
        if (!isBrowser || !currentBrowser.version) {
            return isBrowser
        }

        return isBrowser
    })

    return !!supportedBrowser
}

interface IPaymentTypeRequirements {
    requiredCurrencies?: TCurrency[]
    requiredMinAmount?: { [currency in TCurrency]?: number }
    requiredMaxAmount?: { [currency in TCurrency]?: number }
}
const paymentTypeRequirements: { [type in TPaymentType]?: IPaymentTypeRequirements } = {
    AccountToAccount: {
        requiredCurrencies: ["DKK", "SEK"],
    },
    MobilePayOnline: {
        requiredCurrencies: ["DKK", "NOK", "SEK", "EUR"],
    },
    KlarnaFinancing: {
        requiredCurrencies: ["EUR", "USD", "GBP", "SEK", "NOK"],
        requiredMinAmount: {
            SEK: 250,
        },
    },
    KlarnaPayLater: {
        requiredCurrencies: ["EUR", "USD", "GBP", "DKK", "SEK", "NOK"],
    },
    Vipps: {
        requiredCurrencies: ["NOK"],
    },
    Swish: {
        requiredCurrencies: ["SEK"],
    },
    Viabill: {
        requiredCurrencies: ["DKK"],
    },
    Anyday: {
        requiredCurrencies: ["DKK"],
        requiredMinAmount: {
            DKK: 300,
        },
        requiredMaxAmount: {
            DKK: 30000,
        },
    },
    // Resurs requirements are dynamic and defined in config
}

export function isPaymentTypeSupportedForAmount(
    paymentType: TPaymentType,
    paymentTypeData: IPaymentLinkSupportedPaymentType,
    amount: number,
    currency?: TCurrency
) {
    if (paymentType === "ResursInvoice" || paymentType === "ResursPartPayment" || paymentType === "ResursRevolvingCredit") {
        const resursConfiguration = paymentTypeData.configuration as IResursConfiguration

        const isAmountWithinRange =
            amount >= resursConfiguration.minPurchaseLimit && amount <= resursConfiguration.maxPurchaseLimit

        const isCurrencySupported = resursConfiguration.supportedCurrencies.some(
            (supportedCurrency) => supportedCurrency === currency
        )

        return isCurrencySupported && isAmountWithinRange
    }

    const requirements = paymentTypeRequirements[paymentType]
    if (!requirements || !currency) {
        return true
    }

    if (requirements.requiredCurrencies && requirements.requiredCurrencies.length > 0) {
        if (requirements.requiredCurrencies.indexOf(currency) < 0) {
            return false
        }
    }

    if (requirements.requiredMinAmount && requirements.requiredMinAmount[currency]) {
        const minPrice = requirements.requiredMinAmount[currency] ?? 0
        if (minPrice > amount) {
            return false
        }
    }

    if (requirements.requiredMaxAmount && requirements.requiredMaxAmount[currency]) {
        const maxPrice = requirements.requiredMaxAmount[currency] ?? Number.MAX_SAFE_INTEGER
        if (maxPrice < amount) {
            return false
        }
    }
    return true
}

export function isPaymentTypeSupportedInCurrentConfiguration(
    paymentType: TPaymentType,
    paymentTypeData: IPaymentLinkSupportedPaymentType
) {
    if (paymentType === "ApplePay") {
        const applePayConfiguration = paymentTypeData.configuration as IApplePayConfiguration
        return applePayConfiguration.canPrepareAtSwiipe
    }
    return true //supported
}

export const getPaymentTypeSortingValue = (type: TPaymentType) => {
    switch (type) {
        case "Swish":
            return "5"
        case "MobilePayOnline":
            return "5"
        case "Viabill":
            return "10"
        case "Anyday":
            return "11"
        case "AccountToAccount":
            return "1"
        case "CreditCard":
            return "2"
        case "Vipps":
            return "5"
        case "KlarnaPayLater":
            return "8"
        case "KlarnaFinancing":
            return "8"
        case "ApplePay":
            return "9"
        case "ResursInvoice":
            return "10"
        case "ResursPartPayment":
            return "10"
        case "ResursRevolvingCredit":
            return "10"
        default:
            return type
    }
}

export const hasPaymentTypePaymentComponent = (paymentType: TPaymentType | undefined): boolean => {
    if (!paymentType) {
        return false
    }
    const paymentTypes: TPaymentType[] = ["CreditCard", "ResursInvoice", "ResursPartPayment", "ResursRevolvingCredit", "ApplePay"]
    return paymentTypes.some((typeWithPaymentComp) => typeWithPaymentComp == paymentType)
}
